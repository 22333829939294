import { japaneseTranslate } from './../classModel/jpTranslate';
import { englishTranslate } from './../classModel/enTranslate';
import { spanishTranslate } from './../classModel/esTranslate';
import { User } from './../classModel/user';
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { SESSION_STORAGE, StorageService, LOCAL_STORAGE } from 'angular-webstorage-service';
import { RefreshTokenService } from './interceptors/refreshToken.Service';
import { Subject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { apiData } from './../common';
import { Permissions } from './permission';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../modules/login/login.service';
import { theme } from '../classModel/themes';
import { ThemeService } from './services/theme.service';
// import * as CryptoJS from 'crypto-js';
// import { ThemeService } from '../shared/theme.service';

const KEY = 'key';
const TOKEN = 'TOKEN';
const USER = 'user';
const LOGINDETAILS = 'loginDetails'
const MENULIST = 'menulist'
// const USERDETAIL = 'userDetail';
const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
// const REMEMBER_ME = 'REMEMBER_ME';
const BREADCRUMB = 'breadcrumb';
const CLEARUTC = 'clearUTC';
const PRIVACYPOLICY = 'acceptPrivacyPolicy';
// const LANGUAGEID = 'languageId'; 
const DATEFORMAT = 'dateFormat';
const TIMEFORMAT = 'timeFormat';
const jwtHelper: JwtHelperService = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  public user: User;
  public permission: Permissions;
  public tokenExpired: boolean = false;
  public refreshExpired: boolean = false;
  storage: any;
  interactionEventsAuth = new EventEmitter<any>();
  constructor(private _route: Router, @Inject(SESSION_STORAGE) private _storage: StorageService, @Inject(LOCAL_STORAGE) private _localStorage: StorageService, private _http: HttpClient, private _tokenPocessing: RefreshTokenService, private _translationService: TranslateService, private _themeService: ThemeService) {
  }

  toggleStorage() {
    // if (this._localStorage.get(REMEMBER_ME)) {
    //   this.storage = this._localStorage;
    // } else {
    //   this.storage = this._localStorage;//this._storage;
    // }
    this.storage = this._localStorage;
    return this.storage;
  }

  startSession(tokenJson: any) {
    // this._localStorage.set(REMEMBER_ME, rememberMe);
    this.storage = this.toggleStorage()
    this.setUser(tokenJson);
  }

  // endSession(user: any) {
  //   this.loggedOut();
  // }

  superadmin() {
    if (this.getRole() === 'superadmin') {
      return true;
    }
    return false;
  }

  pmo() {
    if (this.getRole() === 'pmo' || this.getRole() === 'superadmin') {
      return true;
    }
    return false;
  }

  fieldTech() {
    if (this.getRole() === 'fieldtech') {
      return true;
    }
    return false;
  }

  client() {
    if (this.getRole() === 'client') {
      return true;
    }
    return false;
  }
  
  setUser(token) {
    this.storage = this.toggleStorage();
    const self = this;
    // console.log('Setting User');
    const user = jwtHelper.decodeToken(token.access_token);

    this.saveTokens(token);

    let headers = new HttpHeaders();
    headers = headers.append('username', encodeURI(user.user_name));
    let body = {userName: user.user_name};
    this._http.post(apiData.url + apiData.user.userDetails, body , { headers }).subscribe((res: user.UserResponse) => {
      // user.user_name
      if (res.status === 'ok') {

        self.setUserDetail(res);
      } else {
        // console.log('Failed in Submit User');
      }
    }, error => {
      throw new Error(JSON.stringify(error));
    });
    
    // this._http.get(apiData.url + apiData.oldUser + user.user_name, { headers }).subscribe((res: user.UserResponse) => {
    //   //  user.user_name
    //   if (res.status === 'ok') {
    //     self.setUserDetail(res);
    //   } else {
    //     // console.log('Failed in Submit User');
    //   }
    // }, error => {
    //   throw new Error(JSON.stringify(error));
    // });

    // this.setPermission();
  }

  setUserDetail(res: user.UserResponse) {

    this.storage = this.toggleStorage();
    this.interactionEventsAuth.emit(res.user.language);
    this._translationService.use(res.user.language);
    // let user = res.user;
    const user = new User();
    user.email = res.user.email;
    user.firstName = res.user.firstName;
    user.lastName = res.user.lastName;
    user.role = res.user.role.name;
    user.roleId = res.user.role.id; //roleId
    user.userId = res.user.email;
    user.fullName = res.user.fullName;
    user.image = res.user.userImageUrl ? res.user.userImageUrl : null;
    user.theme = res.user.themeId;
    user.username = res.user.userName;
    user.language = res.user.language;
    user.languageId = res.user.languageId;
    user.aliasName = res.user.role.nameAlias; //roleDetail.name;
    user.acceptPolicyUtc = res.user.acceptPolicyUtc;
    user.lastClearedNotificationsUTC = res.user.lastClearedNotificationsUTC;
    user.pwdExpiryDurationLeft = res.user.pwdExpiryDurationLeft;
    user.lastLogin = res.user.lastLogin;
    user.permissions = res.user.role.permissions;
    user.bOneCustomerName = res.user.bOneCustomerName;
    user.bOneCustomerLogo = res.user.bOneCustomerLogo;
    user.dateFormat = res.user.dateFormat ? res.user.dateFormat : 'dd/mm/yyyy';
    user.timeFormat = res.user.timeFormat ? res.user.timeFormat : 'h:mm a';

    // this.storage.set(KEY, user.email);
    this.storage.set(CLEARUTC, user.lastClearedNotificationsUTC);
    this.storage.set(PRIVACYPOLICY, user.acceptPolicyUtc);
    this.storage.set(USER, JSON.stringify(user));

    this.storage.set(DATEFORMAT, user.dateFormat);
    this.storage.set(TIMEFORMAT, user.timeFormat);

    // console.log('Theme Set' + this.getTheme());
    // this.redirect(user.role);
    this._themeService.changeTheme(user.theme);
    this._translationService.setTranslation('en', englishTranslate);
    this._translationService.setTranslation('jp', japaneseTranslate);
    this._translationService.setTranslation('es', spanishTranslate);
    this._translationService.setTranslation('en-US', englishTranslate);
    this._translationService.setTranslation('ja-JP', japaneseTranslate);
    this._translationService.setTranslation('es-SP', spanishTranslate);

    if(user.languageId == 1) {
      this._translationService.use('en-US');
      const d = new Date();
      d.setTime(d.getTime() + (15*24*60*60*1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = 'defaultLanguageUse'+"="+'en-US'+ ";" + expires +";" + ";path=/"

    } 
    if(user.languageId == 2) {
      this._translationService.use('ja-JP');
      const d = new Date();
      d.setTime(d.getTime() + (15*24*60*60*1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = 'defaultLanguageUse'+"="+'ja-JP'+ ";" + expires +";" + ";path=/"
    }
    if(user.languageId == 3) {
      this._translationService.use('es-SP');
      const d = new Date();
      d.setTime(d.getTime() + (15*24*60*60*1000));
      let expires = "expires=" + d.toUTCString();
      document.cookie = 'defaultLanguageUse'+"="+'es-SP'+ ";" + expires +";" + ";path=/"
    }

  }

  // saveLoginDetails(res){
  //   this.storage.set(LOGINDETAILS, JSON.stringify(res));
  // }

  setPermission() {
    // switch (this.user.role) {
    //   case 'superadmin':
    //     this._permissionsService.loadPermissions(['PMO'])
    //     break;
    //   case 'pmo':
    //     this._permissionsService.loadPermissions(['PMO'])
    //     break;
    //   case 'fieldtech':
    //     this._permissionsService.loadPermissions(['FIELDTECH']);
    //     break;
    //   case 'client':
    //     this._permissionsService.loadPermissions(['CLIENT'])
    //     break;
    // }
    // console.log('Permission Added')
    // End
  }

  redirect(role) {
    // const returnUrl = JSON.parse(localStorage.getItem('returnUrl'));
    this._route.navigate(['/home']);
  }

  getTrimmedEmail() {
    const name = this.getUserEmail();
    return name.substr(0, name.indexOf('@')).toLowerCase();
  }

  getUserName() {
    this.storage = this.toggleStorage();
    return encodeURI(JSON.parse(this.storage.get(USER)).username);
  }

  getPrivacyPloicy() {
    this.storage = this.toggleStorage();
    return JSON.parse(this.storage.get(PRIVACYPOLICY));
  }

  getFullName() {
    this.storage = this.toggleStorage();
    return JSON.parse(this.storage.get(USER)).firstName + ' ' + JSON.parse(this.storage.get(USER)).lastName;
  }

  getLastClearUTC() {
    this.storage = this.toggleStorage();
    return JSON.parse(this.storage.get(CLEARUTC));
  }

  getDateFormat() {
    this.storage = this.toggleStorage();
    return this.storage.get(DATEFORMAT);
  }

  getTimeFormat() {
    this.storage = this.toggleStorage();
    return this.storage.get(TIMEFORMAT);
  }

  redirectUrl(url) {
    this._route.navigate(['./', url]);
  }

  getRole(): string {
    this.storage = this.toggleStorage();
    // console.log('Getting User Role'+JSON.parse(this.storage.get(USER)).role);
    return JSON.parse(this.storage.get(USER)).role;
  }

  getRoleId(): string {
    this.storage = this.toggleStorage();
    // console.log('Getting User Role'+JSON.parse(this.storage.get(USER)).role);
    return JSON.parse(this.storage.get(USER)).roleId;
  }

  getRoleAliasName(): string {
    this.storage = this.toggleStorage();
    // console.log('Getting User Role'+JSON.parse(this.storage.get(USER)).role);
    return JSON.parse(this.storage.get(USER)).aliasName;
  }

  getImage(): string {
    this.storage = this.toggleStorage();
    // console.log('Getting User Role'+JSON.parse(this.storage.get(USER)).role);
    return JSON.parse(this.storage.get(USER)).image;
  }

  // setImage(image) {
  //   this.storage.get(USER)
  // }

  getTheme(): string {
    this.storage = this.toggleStorage();
    // console.log('Getting User Role'+JSON.parse(this.storage.get(USER)).role);
    const theme = JSON.parse(this.storage.get(USER)).theme;
    if (theme === '' || theme === null || theme === undefined) {
      return 'light';
    }
    return theme;
  }

  setTheme(theme) {
    this.storage = this.toggleStorage();
    // console.log('Getting User Role'+JSON.parse(this.storage.get(USER)).role);
    const user = JSON.parse(this.storage.get(USER));
    user.theme = theme;
    this.storage.set(USER, JSON.stringify(user));
  }

  isLoggedIn(): boolean {
    this.storage = this.toggleStorage();
    const user = JSON.parse(this.storage.get(USER));
    if (this.storage.get(ACCESS_TOKEN) == null) {
      
      return false;

    } else {

      // return true;
      let pwsExpiryDays = user.pwdExpiryDurationLeft ? user.pwdExpiryDurationLeft : null;
      let localTime = new Date();
      let localTimestamp = localTime.getTime();
      let difference = pwsExpiryDays - localTimestamp;
      let checkNagtiveValue = Math.sign(difference);

      if (user.lastLogin == null) { // login screen
        return false;
      } else if (checkNagtiveValue >= 0 || pwsExpiryDays == null) { // true -> home screen
        return true;
      } else { // login screen
        return false;
      }
      
    }
  }
  
  getUserEmail(): string {
    this.storage = this.toggleStorage();
    return JSON.parse(this.storage.get(USER)).email;
  }

  getName() {
    this.storage = this.toggleStorage();
    return JSON.parse(this.storage.get(USER)).firstName.toUpperCase();
  }

  loggedOut(flag?) {
    
    if (!flag) {
      localStorage.removeItem('returnUrl');
    }
    this.storage = this.toggleStorage();
    this._tokenPocessing.processing = false;

    this.storage.remove(KEY);
    this.storage.remove(TOKEN);

    this.storage.remove(USER);
    this.storage.remove(ACCESS_TOKEN);
    this.storage.remove(REFRESH_TOKEN);
    this.storage.remove(MENULIST);
    this.storage.remove(LOGINDETAILS);
    // this._localStorage.remove(REMEMBER_ME);
    this._storage.remove(BREADCRUMB);

    document.cookie = 'iop.rememberme' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    this._themeService.changeTheme('default');
    // this._permissionsService.flushPermissions();
    this._route.navigate(['./']);

  }

  getToken() {
    this.storage = this.toggleStorage();
    return this.storage.get(ACCESS_TOKEN);
  }

  customTokenExpiried(): boolean {
    this.storage = this.toggleStorage();
    // //;
    // let tokenExpTimeStamp=jwtHelper.decodeToken(this.storage.get(ACCESS_TOKEN)).exp
    // let tokenExpDate=new Date(tokenExpTimeStamp * 1000);
    // tokenExpDate.setSeconds(tokenExpDate.getSeconds()-10);
    // let now= new Date();
    // return tokenExpDate<now
    return jwtHelper.isTokenExpired(this.storage.get(ACCESS_TOKEN), 30);
  }

  customRefreshTokenExpiried(): boolean {
    this.storage = this.toggleStorage();
    return jwtHelper.isTokenExpired(this.storage.get(REFRESH_TOKEN), 30);
  }

  getHeaderAuthorization() {
    this.storage = this.toggleStorage();
    return 'Bearer ' + this.getToken();
  }

  hasAuthorization() {
    this.storage = this.toggleStorage();
    // console.log('Has Auth Called');
    if (this.storage.get(ACCESS_TOKEN) && !jwtHelper.isTokenExpired(this.storage.get(ACCESS_TOKEN), 30)) {
      return true;
    } else {
      return false;
    }
  }

  hasToken() {
    this.storage = this.toggleStorage();
    if (this.storage.get(ACCESS_TOKEN)) {
      return true;
    } else {
      return false;
    }
  }

  hasAuthorizationRefresh() {
    this.storage = this.toggleStorage();
    // console.log('Has Refresh Auth Called')
    if (this.storage.get(REFRESH_TOKEN)) {
      return true;
    } else {
      return false;
    }
  }

  refreshToken(): Observable<any> {
    this.storage = this.toggleStorage();
    this._tokenPocessing.processing = true;
    // console.log('Refresh Token Called')
    // console.log('Refresh Token'+this.getRefreshToken())
      // console.log("Refresh Token called >>");
      const body = new HttpParams()
      .set(`refresh_token`, this.getRefreshToken())
      .set(`grant_type`, 'refresh_token');

      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers = headers.append('Authorization', 'Basic Yi1vbmU6Yi1vbmU=');
      return new Observable(observer => {
        this._http.post(apiData.UASTokenUrl, body.toString(), { headers }).subscribe((res: any) => {
          if (res.access_token) {
            observer.next(res);
            observer.complete();
          }
        }, error => {
          // retry(2), error1 => {
          // this.loggedOut();
          console.log("error > ", error);
        });
      });
  }
  
  saveTokens(token) {
    this.storage = this.toggleStorage();
    this.tokenExpired = false;
    this.storage.set(ACCESS_TOKEN, token.access_token);
    this.storage.set(REFRESH_TOKEN, token.refresh_token);
    // console.log('T '+token.access_token)
    //  console.log('R '+token.refresh_token)
  }
  
  getRefreshToken() {
    this.storage = this.toggleStorage();
    if (this.storage.get(REFRESH_TOKEN) && !jwtHelper.isTokenExpired(this.storage.get(REFRESH_TOKEN))) {
      return this.storage.get(REFRESH_TOKEN);
    } else {
      if (this.storage.get(REFRESH_TOKEN)) {
        return this.storage.get(REFRESH_TOKEN);
      }
      return false;
    }
  }
  
  login(loginVal) {
    this.storage = this.toggleStorage();
    // // ParamData
    // const body = new HttpParams()
    //   .set(`username`, loginVal.value.userName)
    //   .set(`password`, loginVal.value.password)
    //   .set(`grant_type`, 'password');

    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    // headers = headers.append('Authorization', 'Basic Yi1vbmU6Yi1vbmU=');
    // // console.log(body.toString());
    // return this._http.post(apiData.UASTokenUrl,
    //   body.toString(), { headers });

    // Param Data
    const formData = new FormData();
    formData.append('username', loginVal.value.userName);
    formData.append('password', loginVal.value.password);
    formData.append('grant_type', 'password');  
    formData.append("reportProgress", "true");

     let headers = {
      "Authorization": "Basic Yi1vbmU6Yi1vbmU="
    }
    return this._http.post(apiData.UASTokenUrl,formData, {headers} );
  }

  autoLoginUsingToken(loginValue) {
    this.storage = this.toggleStorage();
    const formData = new FormData();
    formData.append('username', loginValue.username);
    formData.append('password', loginValue.password);
    formData.append('grant_type', 'password');  
    formData.append("reportProgress", "true");

     let headers = {
      "Authorization": "Basic Yi1vbmU6Yi1vbmU="
    }
    return this._http.post(apiData.UASTokenUrl,formData, {headers} );
  }
  
  deleteToken() {
    this.storage = this.toggleStorage();
    // console.log('Token Expired')
    this.tokenExpired = true;
  }

  deleteRefreshToken() {
    this.storage = this.toggleStorage();
    // console.log('Rferesh Expired')
    this.refreshExpired = true;
  }

  // getRoleModule() {
  //   this.storage = this.toggleStorage();
  //   console.log('Getting User module>>>', JSON.parse(this.storage.get(USER)).module);
  //   return JSON.parse(this.storage.get(USER)).module;
  // }

  getRolePermissions() {
    this.storage = this.toggleStorage();
    //console.log('Getting User Role>>>', JSON.parse(this.storage.get(USER)).permissions);
    return JSON.parse(this.storage.get(USER)).permissions;//JSON.parse(this.storage.get(USER)).module;
  }

  getPermission(keys): boolean {
    let isAllow: boolean = false;
    //const currentObject = this.getRoleModule();
    // console.log("keys >>", keys)
    const currentObject =  this.getRolePermissions();
    currentObject.filter(rules => {
      // if(rules.resourceName.toLowerCase() === keys[0].toLowerCase()) { // Resource Name
        if(parseInt(rules.permissionId) === parseInt(keys[1])) { // permission ID
          // if(rules.actionName.toLowerCase() === keys[2].toLowerCase()) { // Action Name
            isAllow = true;
          // }
        }
      // }
    })
    return isAllow;
  }

  setCookie(cLabel,cValue,exipryDays) {
    const d = new Date();
    d.setTime(d.getTime() + (exipryDays*24*60*60*1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cLabel+"="+cValue+ ";" + expires +";" + ";path=/"
  }

  getCookie(cLabel) {
    let cookie = {};
    document.cookie.split(';').forEach((el) => {
      let [k,v] = el.split('=');
      cookie[k.trim()] = v;
    });
    return cookie[cLabel];
  }  

  // encrypt(value : string) : string{
  //   return CryptoJS.AES.encrypt(value, "key").toString();
  // }

  // decrypt(textToDecrypt : string){
  //   return CryptoJS.AES.decrypt(textToDecrypt, "key").toString(CryptoJS.enc.Utf8);
  // }

}