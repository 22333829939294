import { Injectable, EventEmitter } from '@angular/core';
import { apiData } from 'src/app/common';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { Observable } from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  errorMessage: boolean;
  public profilePic = new EventEmitter<any>();

  constructor(private _commonDataService: CommonDataService, private _translationService: TranslateService) { }

  getUserDetails(getUserName) {
    let payload = { userName : getUserName};
    return new Observable(observer => {
      this._commonDataService.postData(payload, apiData.url + apiData.user.userDetails).subscribe((editProfileResponse: user.UserResponse) => {
        if (editProfileResponse.status === 'ok') {
          observer.next(editProfileResponse);
          observer.complete();
        }
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });  
    // let url = apiData.url + apiData.user.defaultUrl + '/' + userName;
    // return new Observable(observer => {
    //   this._commonDataService.getData(url).subscribe((editProfileResponse: user.UserResponse) => {
    //     if (editProfileResponse.status === 'ok') {
    //       observer.next(editProfileResponse);
    //       observer.complete();
    //     }
    //   }, error => {
    //     throw new Error(JSON.stringify(error));
    //   });
    // });
  }

  updateData(data, userName): Observable<any> {
    let url = apiData.url + apiData.user.updateUserImageUrl; //userImageUrl; + '/' + userName
    return new Observable(observer => {
      this._commonDataService.putDataOnlyIOPHeader(data, url).subscribe((editProfileResponse: any) => {
        if (editProfileResponse.status === 'ok') {
          this.profilePic.emit();
          observer.next(editProfileResponse);
          observer.complete();
        } else {
          observer.next(editProfileResponse);
          observer.complete();
        }
      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });

  }

  changePassword(data): Observable<any> {
    let url = apiData.url + apiData.changePassword;
    return new Observable(observer => {
      this._commonDataService.putDataOnlyIOPHeader(data, url).subscribe((changePasswordResponse: any) => {

        observer.next(changePasswordResponse);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });
  }

  getLanguageList() {
    let url = apiData.url + apiData.languageList
    return new Observable(observer => {
      this._commonDataService.getDataIoPUserID(url).subscribe((langRes: language.LanguageResponse) => {

        observer.next(langRes);
        observer.complete();

      }, error => {
        throw new Error(JSON.stringify(error));
      });
    });

  }

}