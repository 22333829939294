import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Status } from './../../../helpers/config/status';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { CustomerService } from 'src/app/modules/iop-module/customers/customer.service';
import { MessageBoxService } from '../message-box/message-box.service';
import { SensorService } from 'src/app/modules/iop-module/sensors/sensor.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-remove-sensor',
  templateUrl: './remove-sensor.component.html',
  styleUrls: ['./remove-sensor.component.scss']
})
export class RemoveSensorComponent implements OnInit {

  @Input() getSensorDetails: any;
  @Input() reloadScreenFlag: any;
  @Output() setPopupClose = new EventEmitter<boolean>();
  @Output() setReloadScreen = new EventEmitter<boolean>();

  removeSensor: FormGroup;
  sensorRowId: any;
  textareaFiledFlag: boolean = false;
  navigationSubscription;
  reloadPage:boolean = false;
  removeSensorBtnFlag:boolean = false;
  alertMsg: string;
  showMsg: boolean = false;
  checkboxMsg: string;
  succMsg: string;

  constructor(private formBuilder: FormBuilder, private _authService: AuthenticationService, private _customerService: CustomerService, private _messageBoxService: MessageBoxService, private _sensorService: SensorService, private _location: Location) {
    // Form Validation
    this.removeSensor = this.formBuilder.group({
      'faultySensor': ['', [Validators.required]],
      // 'other': '',
      'permanentlyRemove': []
    });

  }

  ngOnInit() {
    
    this.sensorRowId = this.getSensorDetails.id;
    // this.removeSensor.get('permanentlyRemove').setErrors({ remove: true });
    // sensors/detail
  }

  // radio btns
  onOtherReason(name) {
    if(name == "malfunctioning") {

      this.alertMsg = "This sensor will be marked as malfunctioning and removed permanently. The removed sensor cannot be reused.";
      this.checkboxMsg = "I confirm the permanent removal of this sensor.";
      this.succMsg = "The sensor has been permanently removed";

    }else if(name == "longer required") {

      this.alertMsg = "This sensor will be removed and can be later added back as required."
      this.checkboxMsg = "I confirm the removal of this sensor.";
      this.succMsg = "The sensor has been successfully removed";
      
    }
    this.showMsg = true;
  }

  onSubmit(value) {
    // console.log("value", value)
    this.deleteSensor(value['faultySensor']);
  }

  // delete Sensor in DB
  deleteSensor(faultySensorFlag) {

    const userName = this._authService.getUserName();
    if (userName == 'indiasuperadmin') {

      this._customerService.getBoneCustomerList().subscribe((customerResponse: any) => {

        if (customerResponse.status == 'ok') {

          const content = customerResponse.data;
          const localArray = [];
          for (let i = 0; i < Object.keys(content).length; i++) {
            const eachObj = content[i];
            localArray.push({
              id: eachObj['customerId'],
              name: eachObj['customerName']
            })
          }

          this.callMessageBox(localArray);

        }

      }, error => {
        console.log("error > ", error);
      });

    } else {

      this._sensorService.removeSensor(this.sensorRowId,faultySensorFlag).subscribe((res: any) => {
        if (res.status === Status.OK) {
          this._messageBoxService.openMessageBox('alert', this.succMsg).subscribe(() => {
            if(!this.reloadScreenFlag) {
              this.setReloadScreen.emit(false);
              this._location.back();
            }else {
              this.setReloadScreen.emit(true);
            }
          })
        }
      });
      // remove sensor
      // this._messageBoxService.openMessageBox('confirm', 'are you sure you want to remove the sensor?').subscribe(res => {
      //   if (res === 'yes') {
      //     this._sensorService.removeSensor(this.sensorRowId,faultySensorFlag).subscribe((res: any) => {
      //       if (res.status === Status.OK) {
      //         this._messageBoxService.openMessageBox('alert', 'sensor successfully deleted').subscribe(() => {
      //           if(!this.reloadScreenFlag) {
      //             this.setReloadScreen.emit(false);
      //             this._location.back();
      //           }else {
      //             this.setReloadScreen.emit(true);
      //           }
      //         })
      //       } else {
      //         this._messageBoxService.openMessageBox('alert', res.message).subscribe(() => {
      //           if(!this.reloadScreenFlag) {
      //             this.setReloadScreen.emit(false);
      //             this._location.back();
      //           }else {
      //             this.setReloadScreen.emit(true);
      //           }
      //         })
      //       }
      //     });
      //   }
      // })

    }

  }

  // call message Box for Bone Customer
  callMessageBox(localArray) {

    this._messageBoxService.openMessageBox('Sensor', localArray, 'Sensor').subscribe(res => {

      if (res.status === 'yes') {
        const bOneCustomerId = res.bOneCustomerId ? res.bOneCustomerId : null;
        let finalSensorIdUrl = this.sensorRowId;
        let msg = 'successfully uninstalled the sensor';
        if (bOneCustomerId) {
          msg = 'successfully reassigned to selected customer';
          finalSensorIdUrl = finalSensorIdUrl + '?boneCustomerId=' + bOneCustomerId;
        }
        this._sensorService.deleteSensor(finalSensorIdUrl).subscribe((res: any) => {
          if (res.status === Status.OK) {
            this._messageBoxService.openMessageBox('alert', msg).subscribe(() => {
              if(!this.reloadScreenFlag) {
                this.setReloadScreen.emit(false);
                this._location.back();
              }else {
                this.setReloadScreen.emit(true);
              }
            });
          } else {
            this._messageBoxService.openMessageBox('alert', res.message).subscribe(() => {
              if(!this.reloadScreenFlag) {
                this.setReloadScreen.emit(false);
                this._location.back();
              }else {
                this.setReloadScreen.emit(true);
              }
            });
          }
        });
      }
    })

  }

  // on confirm
  onConfirm(event) {
    this.removeSensorBtnFlag = event.target.checked;
    // console.log("this.removeSensorBtnFlag >>", this.removeSensorBtnFlag)
  }

  // Cancel Popup
  onCancelPopup() {
    this.setPopupClose.emit(false);
  }

}