export enum PestColor {
    COCKROACH = '#68C1EF',
    CRAWLINGINSECT = '#68C1EF',
    FRUITFLY = '#19A1D4',//'#5d98cb',
    HOUSEFLY = '#B6CE34',//'#2978E2',
    FLY = '#DEA25D',
    RODENT = '#746AE0', //'#bab2c3'
    CIGARETTEBEETLE = '#C4BC7C',
    TOBACCOBEETLE = '#C4BC7C',
    FLYINGINSECT = '#DEA25D'
}